/*import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import {  Typography, Button, Box } from '@mui/material';

// Material Kit 2 React themes
import theme from "assets/theme";
import AinaHome from "pages/AinaHome";
//import CarDetail_reserva from "pages/AinaCarProduct/carDetail_reserva";
import AinaCompany from "pages/AinaCompany";
import AinaProtect from "pages/AinaProtect";
import AinaAntes from "pages/AinaAntes";
import AinaCondiciones from "pages/AinaCondiciones";
import CookiesPolicy from "pages/AinaCookies";
import PrivacyPolicy from "pages/AinaPrivacidad";
import TermsAndConditions from "pages/AinaTerminos";
import DamagePolicy from "pages/AinaDanger";
import LegalNotice from "pages/AinaLegal";
import AinaFleet from "pages/AinaFleet";

import AinaFAQ from "pages/AinaFAQ";
import NotFoundPage from "pages/AinaHome/404";
import CookieConsent from "./CookieComponent";

import Login from './pages/AinaDashboard/Login';
import Dashboard from './pages/AinaDashboard/';


export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });




  return (
    //<EnvChecker />
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieConsent />
      <Routes>
        {//<Route path="/productos/:id" element={<ProductDetail />} />
        }
        <Route path="" element={<AinaHome />} />
        <Route path="/home" element={<AinaHome />} />
        {//<Route path="/reserva" element={<CarDetail_reserva />} />
        }
        <Route path="/company" element={<AinaCompany />} />
        <Route path="/flota" element={<AinaFleet />} />

        <Route path="/protect" element={<AinaProtect />} />
        <Route path="/informacion-antes-de-alquilar" element={<AinaAntes />} />
        <Route path="/terminos-y-condiciones-generales" element={<AinaCondiciones />} />
        <Route path="/politica-cookies" element={<CookiesPolicy />} />
        <Route path="/politica-privacidad" element={<PrivacyPolicy />} />
        <Route path="/terminos-y-condiciones" element={<TermsAndConditions />} />
        <Route path="/politica-gestion-de-danos" element={<DamagePolicy />} />
        <Route path="/aviso-legal" element={<LegalNotice />} />
        <Route path="/faqs" element={<AinaFAQ />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}*/


import React, { useEffect, useState, useRef } from "react";
import { Routes, Route, Navigate, useLocation  } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';

// Material Kit 2 React themes
import theme from "assets/theme";
import AinaHome from "pages/AinaHome";
import AinaCompany from "pages/AinaCompany";
import AinaPromo from "pages/AinaPromo";

import AinaProtect from "pages/AinaProtect";
import AinaAntes from "pages/AinaAntes";
import AinaAntes2 from "pages/AinaAntes2";
import AinaCondiciones from "pages/AinaCondiciones";
import CookiesPolicy from "pages/AinaCookies";
import PrivacyPolicy from "pages/AinaPrivacidad";
import TermsAndConditions from "pages/AinaTerminos";
import DamagePolicy from "pages/AinaDanger";
import LegalNotice from "pages/AinaLegal";
import AinaFleet from "pages/AinaFleet";
import AinaFAQ from "pages/AinaFAQ";
import NotFoundPage from "pages/AinaHome/404";
//import CookieConsent from "./CookieComponent";
import CookieBanner from "./CookieBanner";
import Login from './pages/AinaDashboard/Login';
import Dashboard from './pages/AinaDashboard/';
import PrivateRoute from './pages/AinaDashboard/PrivateRoute'; // Importar el componente PrivateRoute

import useFacebookPixel from "./useFacebookPixel";

export default function App() {
  const { pathname } = useLocation();
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const facebookPixelId = process.env.REACT_APP_META_TOKEN;
  useFacebookPixel(facebookPixelId);

  const location = useLocation();
  // const firstRender = useRef(true); // 🔥 Para evitar la recarga en el primer render

  // useEffect(() => {
  //   if (firstRender.current) {
  //     firstRender.current = false; // ⚠️ Evita recargar en el primer render
  //     return;
  //   }

  //   console.log("🔄 Recargando la página por cambio de ruta:", location.pathname);
  //   window.location.href = location.pathname; // 🔥 Recarga sin caer en bucle infinito

  // }, [location.pathname]); // Se ejecuta en cada cambio de URL

  // Configurar el scroll a 0 cuando cambie la ruta
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const setAuthToken = (token) => {
    setToken(token);
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  };

  const routes = [
    { path: "/", component: AinaHome },
    { path: "/home", component: AinaHome },
    { path: "/reservar", component: AinaHome },
    { path: "/company", component: AinaCompany },
    { path: "/ofertas", component: AinaPromo },
    { path: "/flota", component: AinaFleet },
    { path: "/protect", component: AinaProtect },
    { path: "/guia-de-cargos-adicionales", component: AinaAntes },
    { path: "/informacion-antes-de-alquilar", component: AinaAntes2 },
    { path: "/terminos-y-condiciones-generales", component: AinaCondiciones },
    { path: "/politica-cookies", component: CookiesPolicy },
    { path: "/politica-privacidad", component: PrivacyPolicy },
    { path: "/terminos-y-condiciones", component: TermsAndConditions },
    { path: "/politica-gestion-de-danos", component: DamagePolicy },
    { path: "/aviso-legal", component: LegalNotice },
    { path: "/faqs", component: AinaFAQ },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {//<CookieConsent />
      }
      {<CookieBanner/>
      }
      <Routes>
        {/* Generar rutas principales y /reservar dinámicamente */}
        {routes.map(({ path, component: Component }) => (
          <React.Fragment key={path}>
            <Route path={path} element={<Component />} />
            <Route
              path={`${path}/reservar`}
              element={<Component isReservationOpen={true} />}
            />
          </React.Fragment>
        ))}

        {/* Rutas adicionales */}
        <Route path="/login" element={<Login setToken={setAuthToken} />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard token={token} />
            </PrivateRoute>
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
