import * as React from 'react';
import { useState, useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Card, CardContent, Typography, FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import expocar01 from 'assets/images/ainacar-bola-remolque.png';
import CenteredBlogCardProduct2 from "./CenteredBlogCard Product2";
import etiqueta0 from 'assets/images/et0.png';
import etiquetac from 'assets/images/etc.png';
import etiquetaeco from 'assets/images/eteco.png';
import caja from 'assets/images/caja-negra.png';
import axios from 'axios';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MKTypography from "components/MKTypography";
import Container from '@mui/material/Container';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import productosapi from './product.json';

import { styled } from '@mui/system';

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-icon': {
    right: 10,
  },
  '&.Mui-disabled .MuiSelect-icon': {
    color: theme.palette.text.disabled,
  },
}));

const GrowingIcon = styled(LocalShippingIcon)(({ theme, size }) => ({
  transition: 'transform 0.3s ease-in-out',
  margin: '0 auto',
  display: 'block',
  fontSize: size,
  '&:hover': {
    transform: 'scale(1.5)', 
  },
}));

function FiltroProductos({
  productos,
  onFiltroChange,
  selectedCategoria,
  selectedMedio,
  selectedSubcategoria,
  selectedPlazas,
  selectedCombustible,
  selectedCambio
}) {
  const handleCategoriaChange = (event) => {
    onFiltroChange({ categoria: event.target.value, etiqueta: '', segmento: '', plazas: '', combustible: '', transmision: '' });
  };

  const handleMedioChange = (event) => {
    onFiltroChange({ etiqueta: event.target.value });
  };

  const handleSubcategoriaChange = (event) => {
    onFiltroChange({ segmento: event.target.value });
  };

  const handlePlazasChange = (event) => {
    onFiltroChange({ plazas: event.target.value });
  };

  const handleCombustibleChange = (event) => {
    onFiltroChange({ combustible: event.target.value });
  };

  const handleCambioChange = (event) => {
    onFiltroChange({ transmision: event.target.value });
  };

  const handleChipDelete = (chipToDelete) => () => {
    switch (chipToDelete) {
      case 'categoria':
        onFiltroChange({ categoria: '', etiqueta: '', segmento: '', plazas: '', combustible: '', transmision: '' });
        break;
      case 'etiqueta':
        onFiltroChange({ etiqueta: '' });
        break;
      case 'segmento':
        onFiltroChange({ segmento: '' });
        break;
      case 'plazas':
        onFiltroChange({ plazas: '' });
        break;
      case 'combustible':
        onFiltroChange({ combustible: '' });
        break;
      case 'transmision':
        onFiltroChange({ transmision: '' });
        break;
      default:
        break;
    }
  };

  const applyFilters = (productos) => {
    return productos.filter(producto => {
      return (
        (!selectedCategoria || producto.categoria === selectedCategoria) &&
        (!selectedMedio || producto.etiqueta === selectedMedio) &&
        (!selectedSubcategoria || producto.segmento === selectedSubcategoria) &&
        (!selectedPlazas || producto.plazas === selectedPlazas) &&
        (!selectedCombustible || producto.combustible === selectedCombustible) &&
        (!selectedCambio || producto.transmision === selectedCambio)
      );
    });
  };

  const filteredProductos = applyFilters(productos);

  const categorias = [...new Set(filteredProductos.map(producto => producto.categoria))];
  const medios = [...new Set(filteredProductos.map(producto => producto.etiqueta))];
  const subcategorias = [...new Set(filteredProductos.map(producto => producto.segmento))];
  const plazas = [...new Set(filteredProductos.map(producto => producto.plazas))];
  const combustibles = [...new Set(filteredProductos.map(producto => producto.combustible))];
  const cambios = [...new Set(filteredProductos.map(producto => producto.transmision))];

  return (
    <Card sx={{ mb: 3, boxShadow:'none', borderRadius: 2, border: '1px solid  rgba(168, 168, 168, 0.35)'}}>
      <CardContent>
        <Typography variant="h5" gutterBottom mt={2}> 
          Filtrar Vehículos
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          {selectedCategoria && (
            <Chip label={`Categoría: ${selectedCategoria}`} onDelete={handleChipDelete('categoria')} />
          )}
          {selectedMedio && (
            <Chip label={`Medio: ${selectedMedio}`} onDelete={handleChipDelete('etiqueta')} />
          )}
          {selectedSubcategoria && (
            <Chip label={`Subcategoría: ${selectedSubcategoria}`} onDelete={handleChipDelete('segmento')} />
          )}
          {selectedPlazas && (
            <Chip label={`Plazas: ${selectedPlazas}`} onDelete={handleChipDelete('plazas')} />
          )}
          {selectedCombustible && (
            <Chip label={`Combustible: ${selectedCombustible}`} onDelete={handleChipDelete('combustible')} />
          )}
          {selectedCambio && (
            <Chip label={`Cambio: ${selectedCambio}`} onDelete={handleChipDelete('transmision')} />
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Categoría</InputLabel>
              <Select
                sx={{ minHeight: 42 }}
                value={selectedCategoria}
                onChange={handleCategoriaChange}
                label="Categoría"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="">Todas</MenuItem>
                {categorias.map((categoria, index) => (
                  <MenuItem key={index} value={categoria}>{categoria  == 'Furgones y camiones' ? 'Furgonetas y Camiones' : categoria}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!selectedCategoria}>
              <InputLabel>Medio</InputLabel>
              <Select
                sx={{ minHeight: 42 }}
                value={selectedMedio}
                onChange={handleMedioChange}
                label="Medio"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="">Todos</MenuItem>
                {medios.map((etiqueta, index) => (
                  <MenuItem key={index} value={etiqueta}>{etiqueta}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!selectedCategoria}>
              <InputLabel>Subcategoría</InputLabel>
              <Select
                sx={{ minHeight: 42 }}
                value={selectedSubcategoria}
                onChange={handleSubcategoriaChange}
                label="Subcategoría"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="">Todas</MenuItem>
                {subcategorias.map((segmento, index) => (
                  <MenuItem key={index} value={segmento}>{segmento}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!selectedCategoria}>
              <InputLabel>Plazas</InputLabel>
              <Select
                sx={{ minHeight: 42 }}
                value={selectedPlazas}
                onChange={handlePlazasChange}
                label="Plazas"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="">Todas</MenuItem>
                {plazas.map((plazas, index) => (
                  <MenuItem key={index} value={plazas}>{plazas}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!selectedCategoria}>
              <InputLabel>Combustible</InputLabel>
              <Select
                sx={{ minHeight: 42 }}
                value={selectedCombustible}
                onChange={handleCombustibleChange}
                label="Combustible"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="">Todos</MenuItem>
                {combustibles.map((combustible, index) => (
                  <MenuItem key={index} value={combustible}>{combustible}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!selectedCategoria}>
              <InputLabel>Cambio</InputLabel>
              <Select
                sx={{ minHeight: 42 }}
                value={selectedCambio}
                onChange={handleCambioChange}
                label="Cambio"
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value="">Todos</MenuItem>
                {cambios.map((transmision, index) => (
                  <MenuItem key={index} value={transmision}>{transmision}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function CustomCard({}) {
  const { t, i18n } = useTranslation();

  return (
    <Card 
      sx={{ 
        backgroundColor: '#d6061e', 
        boxShadow: 'none', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        pt: 2 
      }}
    >
      <CardContent 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center', 
          textAlign: 'center' 
        }}
      >
        <MKTypography variant="body2" color="white">
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim01')}</span>
          {t('fleet_veh_bola_claim02')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim03')}</span>
          {t('fleet_veh_bola_claim04')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim05')}</span>
          {t('fleet_veh_bola_claim06')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim07')}</span>
          {t('fleet_veh_bola_claim08')}
          <span style={{ fontWeight: 'bold' }}>{t('fleet_veh_bola_claim09')}</span>
        </MKTypography>
      </CardContent>
    </Card>
  );
}

function Productos({ refe, reservationDrawer, selectedCategoria, manejarEstadoPrincipal }) {
  const { t, i18n } = useTranslation();


  const token = 'mi-token-estatico';


  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 6;
  const [totalPages, setTotalPages] = useState(1);
  const [productos, setProductos] = useState([]);
  const [filteredProductos, setFilteredProductos] = useState([]);
  const [filtros, setFiltros] = useState({
    categoria: selectedCategoria || '',
    etiqueta: '',
    segmento: '',
    plazas: '',
    combustible: '',
    transmision: ''
  });
  const [pages, setPages] = useState({});

  const textsCards1 = [
    { title: t('protect_card_title_01'), subtitle: t('protect_card_subtitle_01'), color: "rgba(0, 0, 0, 0.00)" },
    { title: t('protect_card_title_02'), subtitle: t('protect_card_subtitle_02'), color: "rgba(0, 0, 0, 0.00)" },
    { title: t('protect_card_title_03'), subtitle: t('protect_card_subtitle_03'), color: "rgba(0, 0, 0, 0.00)" },
    { title: t('protect_card_title_04'), subtitle: t('protect_card_subtitle_04'), color: "rgba(0, 0, 0, 0.00)" }
  ];

  const textsCards = [
    { title: t('protect_card_title_01'), subtitle: t('protect_card_subtitle_01'), color: "rgba(0, 0, 0, 0.00)" },
    { title: t('protect_card_title_02'), subtitle: t('protect_card_subtitle_02'), color: "rgba(0, 0, 0, 0.00)" },
    { title: t('protect_card_title_03'), subtitle: t('protect_card_subtitle_03'), color: "rgba(0, 0, 0, 0.00)" },
    { title: t('protect_card_title_04'), subtitle: t('protect_card_subtitle_04'), color: "rgba(0, 0, 0, 0.00)" }
  ];

  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchProductos = async () => {
      try {
        // Llamada a la API con el token como parámetro de la URL
        const fetchedProductos = await axios.get(`/api/public/data?token=${token}`);
        
        // Ordena los productos alfabéticamente por el modelo dentro de cada categoría
        const productosOrdenados = fetchedProductos.data.sort((a, b) => {
          if (a.categoria === b.categoria) {
            // Si las categorías son iguales, ordena por el modelo
            return a.nombre.localeCompare(b.nombre);
          }
          // Ordena por categoría en caso de que sean diferentes
          return a.categoria.localeCompare(b.categoria);
        });
  
        // Actualiza los estados con los datos ordenados
        setProductos(productosOrdenados);
        console.log('vehiculos de fleet', productosOrdenados);
        setFilteredProductos(productosOrdenados);
        console.log(productosOrdenados);
  
        // Cálculo de páginas iniciales basado en las categorías de los productos
        const initialPages = productosOrdenados.reduce((acc, producto) => {
          if (!acc[producto.categoria]) {
            acc[producto.categoria] = 1;
          }
          return acc;
        }, {});
  
        // Actualiza el estado de páginas y total de páginas
        setPages(initialPages);
        setTotalPages(Math.ceil(productosOrdenados.length / productsPerPage));
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };
  
    fetchProductos();
  }, [token, productsPerPage]); // Agrega 'token' como dependencia
  
  

  useEffect(() => {
    let filtered = productos;
    if (filtros.categoria) {
      filtered = filtered.filter(producto => producto.categoria === filtros.categoria);
    }
    if (filtros.etiqueta) {
      filtered = filtered.filter(producto => producto.etiqueta === filtros.etiqueta);
    }
    if (filtros.segmento) {
      filtered = filtered.filter(producto => producto.segmento === filtros.segmento);
    }
    if (filtros.plazas) {
      filtered = filtered.filter(producto => producto.plazas === filtros.plazas);
    }
    if (filtros.combustible) {
      filtered = filtered.filter(producto => producto.combustible === filtros.combustible);
    }
    if (filtros.transmision) {
      filtered = filtered.filter(producto => producto.transmision === filtros.transmision);
    }
    setFilteredProductos(filtered);
    setTotalPages(Math.ceil(filtered.length / productsPerPage));
  }, [filtros, productos, productsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filtros]);

  useEffect(() => {
    if (selectedCategoria) {
      setFiltros(prevFiltros => ({ ...prevFiltros, categoria: selectedCategoria }));
    }
  }, [selectedCategoria]);

  const handleFiltroChange = (filtro) => {
    setFiltros(prevFiltros => ({ ...prevFiltros, ...filtro }));
  };

  const categorias = [...new Set(productos.map(producto => producto.categoria))];
  const medios = productos.map(producto => ({ etiqueta: producto.etiqueta, categoria: producto.categoria }));
  const subcategorias = productos.map(producto => ({ segmento: producto.segmento, categoria: producto.categoria }));
  const plazas = productos.map(producto => ({ plazas: producto.plazas, categoria: producto.categoria }));
  const combustibles = productos.map(producto => ({ combustible: producto.combustible, categoria: producto.categoria }));
  const cambios = productos.map(producto => ({ transmision: producto.transmision, categoria: producto.categoria }));

  const groupedProductos = productos.reduce((acc, producto) => {
    if (!acc[producto.categoria]) {
      acc[producto.categoria] = [];
    }
    acc[producto.categoria].push(producto);
    return acc;
  }, {});

  return (
    <Box ref={refe}     
     sx={{ flexGrow: 1, p: 3, mb: 10 }}>
      {filtros.categoria === 'Vehículos con bola de remolque' && (
        <Container sx={{ backgroundColor: 'white' }}>
          <Grid mb={10} container spacing={4}>
            <Grid item xs={12}>
              <CustomCard />
            </Grid>
          </Grid>
        </Container>
      )}
      {filtros.categoria === 'Furgones y camiones' && (
        <Container sx={{ backgroundColor: 'white' }}>
          <Grid mb={2} container spacing={4}>
          <Grid item xs={12}>
      <Container
        sx={{
          mt: { xs: -2, sm: -2, md: -2, lg: -0 },
          mb: 8,
          backgroundColor: '#591C21',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h4" p={4} component="h3" sx={{ textAlign: 'center', color: '#FFFFFF' }}>
          ¿QUE PUEDEN TRANSPORTAR NUESTRAS FURGONETAS?
        </Typography>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          {textsCards1.map((text, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  backgroundColor: text.color,
                  color: 'white',
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 'none',
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                <img src={caja} style={{ width: `${50 + (index * 8)}px`, height: `${50 + (index * 8)}px`}} />

                  <Typography
                    mt={2}
                    color={'#FFFFFF'}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    {text.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: '1.3rem',
                        sm: '1.3rem',
                        md: '1.3rem',
                        lg: '1rem',
                      },
                    }}
                    color="#FFFFFF"
                  >
                    {text.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
          </Grid>
          <Grid mb={10} container spacing={4}>
          <Grid item xs={12}>
      <Container
        sx={{
          mt: { xs: -2, sm: -2, md: -2, lg: -0 },
          mb: 8,
          backgroundColor: '#d6061e',
          borderRadius: '10px',
        }}
      >
        <Typography variant="h4" p={4} component="h3" sx={{ textAlign: 'center', color: '#FFFFFF' }}>
          ¿QUE PUEDEN TRANSPORTAR NUESTROS CAMIONES?
        </Typography>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          {textsCards.map((text, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  backgroundColor: text.color,
                  color: 'white',
                  p: 2,
                  borderRadius: 2,
                  boxShadow: 'none',
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
<LocalShippingIcon style={{ width: `${50 + (index * 8)}px`, height: `${50 + (index * 8)}px`, color:'#FFFFFF' }} />
                  <Typography
                    mt={2}
                    color={'#FFFFFF'}
                    gutterBottom
                    variant="h6"
                    component="div"
                  >
                    {text.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: '1.3rem',
                        sm: '1.3rem',
                        md: '1.3rem',
                        lg: '1rem',
                      },
                    }}
                    color="#FFFFFF"
                  >
                    {text.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
          </Grid>
        </Container>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={3}>
          <FiltroProductos
            productos={productos}
            onFiltroChange={handleFiltroChange}
            selectedCategoria={filtros.categoria}
            selectedMedio={filtros.etiqueta}
            selectedSubcategoria={filtros.segmento}
            selectedPlazas={filtros.plazas}
            selectedCombustible={filtros.combustible}
            selectedCambio={filtros.transmision}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          {filtros.categoria === '' ? (
            Object.keys(groupedProductos).map((categoria, idx) => {
              const currentPageForCategoria = pages[categoria] || 1;
              const totalPagesForCategoria = Math.ceil(groupedProductos[categoria].length / productsPerPage);

              return (
                <Box key={idx} sx={{ mb: 4 }}>
                  <Typography variant="h4" sx={{ mb: 2, mt: idx === 0 ? 0 : 10 }}>
                    {categoria  == 'Furgones y camiones' ? 'Furgonetas y Camiones' : categoria}
                  </Typography>
                  <Grid container spacing={3}>
                    {groupedProductos[categoria]
                      .slice((currentPageForCategoria - 1) * productsPerPage, currentPageForCategoria * productsPerPage)
                      .map((producto, index) => (
                        <Grid key={index} item xs={12} sm={12} md={12} lg={4}>
                          <CenteredBlogCardProduct2
                            image={producto.imagen}
                            title={producto.segmento}
                            description={producto.modelo}
                            action={{ type: 'internal', route: '/details', color: 'primary', label: 'Learn More' }}
                            motor={producto.combustible}
                            cambio={producto.transmision}
                            personas={producto.plazas}
                            maletas={producto.maletas}
                            espec={producto.equipamiento}
                            modelo2={producto.nombre}
                            reservationDrawer={reservationDrawer}
                            categoria={producto.categoria}
                            capacidad={producto.informacion_carga}
                            remolque_sticker={producto.remolque}
                            capacidad_bol={producto.carga_medidas}
                            remolque_bol={producto.remolque}
                            remolque={producto.informacion_remolque}
                            medio={producto.etiqueta}
                            actualizarEstadoPadre={manejarEstadoPrincipal}
                            link={producto.link_reserva}

                          />
                        </Grid>
                      ))}
                  </Grid>
                  <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                      count={totalPagesForCategoria}
                      page={currentPageForCategoria}
                      onChange={(e, page) => setPages((prevPages) => ({ ...prevPages, [categoria]: page }))}
                      variant="contained"
                      size="large"
                      sx={{
                        '& .Mui-selected': {
                          backgroundColor: '#344767',
                          color: '#fff',
                        },
                      }}
                    />
                  </Box>
                </Box>
              );
            })
          ) : (
            <Grid container spacing={3}>
              {filteredProductos
                .slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage)
                .map((producto, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <CenteredBlogCardProduct2
                      image={producto.imagen}
                      title={producto.segmento}
                      description={producto.modelo}
                      action={{ type: 'internal', route: '/details', color: 'primary', label: 'Learn More' }}
                      motor={producto.combustible}
                      cambio={producto.transmision}
                      personas={producto.plazas}
                      maletas={producto.maletas}
                      espec={producto.equipamiento}
                      modelo2={producto.nombre}
                      reservationDrawer={reservationDrawer}
                      categoria={producto.categoria}
                      capacidad={producto.informacion_carga}
                      remolque_sticker={producto.remolque}
                      capacidad_bol={producto.carga_medidas}
                      remolque_bol={producto.remolque}
                      remolque={producto.informacion_remolque}
                      medio={producto.etiqueta}
                      actualizarEstadoPadre={manejarEstadoPrincipal}
                      link={producto.link_reserva}
                     

                    />
                  </Grid>
                ))}
              <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handleChangePage}
                  variant="contained"
                  size="large"
                  sx={{
                    '& .Mui-selected': {
                      backgroundColor: '#344767',
                      color: '#fff',
                    },
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Productos;
