/*import React, { Suspense, useState, useEffect, useRef } from 'react';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { useNavigate } from 'react-router-dom';
import KarveIframe from './ReservationWidget';
import { Card, CardContent, Typography, Grid, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Karve02 = ({ isOpenReservation, reservationDrawer }) => {
  //const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [buttonHeight, setButtonHeight] = useState(0);
  //const [currentStep, setCurrentStep] = useState('');
  //const [nextStep, setNextStep] = useState('');

  useEffect(() => {
    if (buttonRef.current) {
      const height = buttonRef.current.clientHeight;
      setButtonHeight(height);
    }
  }, [isOpenReservation]);

  //const handleStepChange = ({ currentStep, nextStep }) => {
  //  setCurrentStep(currentStep);
  //  setNextStep(nextStep);
  //  // Aquí puedes agregar la lógica que necesites cuando los pasos cambian
  //  console.log('Step changed:', { currentStep, nextStep });
  //};

  const ButtonConfig = isOpenReservation ? "100%" : "0%";
  const ButtonConfig2 = isOpenReservation ? "0%" : "100%";
  const pointerEventsStyle = isOpenReservation ? 'auto' : 'none';

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  const CustomTextField = styled(TextField)({
    '& label': {
      color: '#FFFFFF',
    },
    '& label.Mui-focused': {
      color: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',
        borderWidth: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      color: '#FFFFFF',
    },
  });

  return (
    <>
    {isMobileDevice() ? (
      <Box 
        sx={{ 
          position: 'fixed', 
          top: '90%', 
          left: '0', 
          transform: `translate(-50%, -50%) rotate(90deg)`, 
          zIndex: '1001', 
          opacity: ButtonConfig2 
        }}>
        <MKButton 
          ref={buttonRef} 
          onClick={() => {reservationDrawer()}} 
          variant="contained" 
          color="primary" 
          style={{ 
            borderBottomLeftRadius: '0', 
            borderBottomRightRadius: '0', 
            boxShadow: 'none', 
            padding: '8px 16px', 
            width: 'max-content', 
            marginBottom: buttonHeight 
          }}>
          {isOpenReservation ? '' : 'Reservar'}
        </MKButton>
      </Box>
    ) : (null)}
    
    {isOpenReservation && (
      <div 
        style={{ 
          width: '100%', 
          height: '100%', 
          backdropFilter: `blur(15px)`,
          backgroundColor: 'rgba(0, 0, 0, 0.5)', 
          position: 'fixed', 
          top: '0', 
          left: '0', 
          zIndex: '1000', 
          transition: '0.15s linear' 
        }} 
      />
    )}
    
    <div 
      style={{ 
        width: isMobileDevice() ? '100%' : '70%', 
        height: '100%', 
        backgroundColor: 'white', 
        overflow: 'hidden', // Evitar que se desborde
        position: 'fixed', 
        top: '0', 
        left: '0', 
        zIndex: '1000', 
        opacity: ButtonConfig, 
        pointerEvents: pointerEventsStyle,
        display: 'flex',
        flexDirection: 'column' // Asegura que los elementos hijos se apilen verticalmente
      }}>
      
      <MKBox sx={{ position: 'absolute', top: '0', right: '0', zIndex: 1 }}>
        <MKButton 
          variant="text" 
          onClick={() => { reservationDrawer() }} 
          sx={{ color: 'rgba(0, 0, 0, 0.4)', borderRadius: '5px', padding: '16px' }}>
          Cerrar
        </MKButton>
      </MKBox>
      
      <Card 
        sx={{ 
          minHeight: '80px', 
          width: '90%', 
          marginTop: '20px',
          marginBottom: '20px',  
          padding: '16px', 
          backgroundColor: 'rgba(0, 0, 0, 0.03)', 
          boxShadow: 'none',
          alignSelf: 'center' // Centra la card horizontalmente
        }}>
        <CardContent>
          <Typography variant="h5" color="#434b4d" align="center">
            <span style={{ fontWeight: 'Regular' }}>
              ¡Atención! Puedes encontrar vehículos con bola de remolque. Busca los vehículos que tengan '(+ opción de BOLA REMOLQUE)' junto a su nombre en nuestro motor de búsqueda.
            </span>
          </Typography>
        </CardContent>
      </Card>
      
      <MKBox mt={4} height="100%" width="100%" overflow="auto">
        <Grid 
          container 
          spacing={0} 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            textAlign: 'center', 
            width: '100%' 
          }}>
          
          <Grid 
            item 
            xs={12} 
            sm={12} 
            md={12} 
            xl={12} 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              textAlign: 'center', 
              width: '100%',
              height:'100%' 
            }}>
            {<KarveIframe />}
          </Grid>
          
          <Grid 
            item 
            xs={12} 
            sx={{ 
              width: '100%', 
              height: '100%', 
              padding: '0 16px' 
            }}>
            
            <Card 
              sx={{ 
                minHeight: '200px', 
                width: '90%', 
                marginTop: '20px', 
                margin: '0 auto', 
                padding: '16px', 
                backgroundColor: '#d51317' 
              }}>
              <CardContent>
                <Grid 
                  container 
                  spacing={2} 
                  sx={{ marginTop: '16px' }}>
                  
                  <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center' 
                    }}>
                    <Typography variant="h4" color="#FFFFFF" align="center">
                      <span style={{ fontWeight: 'Regular' }}>
                        ¡Entra a la familia Aina Car y recibe un 5% de descuento en tu primer alquiler reservando por la web!
                      </span>
                    </Typography>
                  </Grid>
                  
                  <Grid 
                    item 
                    xs={12} 
                    md={6}
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center' 
                    }}>
                    <form noValidate autoComplete="off">
                      <CustomTextField
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                      />
                      <CustomTextField
                        label="Correo Electrónico"
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                      />
                      <Button 
                        disabled 
                        sx={{ width: '100%', boxShadow:'none', color:'#031b27' }} 
                        variant="contained" 
                        color="white" 
                        type="submit">
                        Solicitar
                      </Button>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </div>
  </>
  );
};

export default Karve02;*/




/*
import React, { useEffect, useState , useRef} from 'react';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import KarveIframe from './ReservationWidget';
import { Card, CardContent, Typography, Grid, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Karve02 = ({ isOpenReservation, reservationDrawer, estadoPrincipal }) => {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight * 0.8); 
  const buttonRef = useRef(null);
  const [buttonHeight, setButtonHeight] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const height = buttonRef.current.clientHeight;
      setButtonHeight(height);
    }
  }, [isOpenReservation]);

  const CustomTextField = styled(TextField)({
    '& label': {
      color: '#FFFFFF',
    },
    '& label.Mui-focused': {
      color: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',
        borderWidth: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      color: '#FFFFFF',
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(window.innerHeight * 0.8); 
    };

    const handleIframeMessage = (event) => {
      if (event.origin.includes('karveinformatica.com')) {
        if (event.data.height) {
          setIframeHeight(event.data.height);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const ButtonConfig = isOpenReservation ? "100%" : "0%";
  const ButtonConfig2 = isOpenReservation ? "0%" : "100%";
  const pointerEventsStyle = isOpenReservation ? 'auto' : 'none';

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  return (
    <>
      {isMobileDevice() ? (
      <Box 
        sx={{ 
          position: 'fixed', 
          top: '90%', 
          left: '0', 
          transform: `translate(-50%, -50%) rotate(90deg)`, 
          zIndex: '1001', 
          opacity: ButtonConfig2 
        }}>
        <MKButton 
          ref={buttonRef} 
          onClick={() => {reservationDrawer()}} 
          variant="contained" 
          color="primary" 
          style={{ 
            borderBottomLeftRadius: '0', 
            borderBottomRightRadius: '0', 
            boxShadow: 'none', 
            padding: '8px 16px', 
            width: 'max-content', 
            marginBottom: buttonHeight 
          }}>
          {isOpenReservation ? '' : 'Reservar'}
        </MKButton>
      </Box>
    ) : (null)}

      {isOpenReservation && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(15px)',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            position: 'fixed',
            //top: '0',
            left: '0',
            zIndex: '1000'
          }}
        />
      )}

      <div
        style={{
          width: isMobileDevice() ? '100%' : '70%',
          maxHeight: '80%', 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '1000',
          display: isOpenReservation ? 'block' : 'none',
        
          borderRadius: '8px', 

          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)' 
        }}>
        <MKBox sx={{ position: 'absolute', top: '-6%', right: '0%', zIndex: 1 }}>
          <MKButton
            variant="text"
            onClick={reservationDrawer}
            sx={{ color: 'rgba(255, 255, 255, 1)', borderRadius: '5px', padding: '16px' }}>
            Cerrar
          </MKButton>
        </MKBox>
       
        <MKBox
          //height="calc(100% - 64px)" 
          width="100%"
          sx={{ 
            //padding: '16px' 
            }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              height: '100%',
            }}>
            <Grid
              item
              
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' },
                height: '100%',
              }}>
              <div style={{
               
                width: '100%'
              }}>
                <KarveIframe estadoPrincipal={estadoPrincipal} />
              </div>
            </Grid>
            <Card 
      sx={{ 
        width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' }, // Ajusta el ancho para pequeñas pantallas
        marginTop: '20px',
        marginBottom: '20px',  
        padding: { xs: '8px', sm: '12px', md: '16px' }, // Ajusta el padding según el tamaño de la pantalla
        backdropFilter: 'blur(15px)',
        backgroundColor: 'rgba(255, 255, 255, 0.20)', 
        boxShadow: 'none',
        alignSelf: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
      <CardContent>
        <Typography 
          variant="h5" 
          color="#FFFFFF" 
          align="center" 
          sx={{ 
            fontSize: { xs: '16px', sm: '18px', md: '20px' }, // Ajusta el tamaño de fuente para pantallas pequeñas
            wordWrap: 'break-word', // Permite que el texto se ajuste y haga saltos de línea si es necesario
            overflowWrap: 'break-word'
          }}
        >
          <span style={{ fontWeight: 'Regular' }}>
            ¡Atención! Puedes encontrar vehículos con bola de remolque. Busca los vehículos que tengan '(+ opción de BOLA REMOLQUE)' junto a su nombre en nuestro motor de búsqueda.
          </span>
        </Typography>
      </CardContent>
    </Card>
            <Grid 
            item 
            sx={{ 
              width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' },
              height: '100%', 
              marginTop: '0px', 

            }}>
            
            <Card 
              sx={{ 
                minHeight: '200px', 
                width: '100%', 
                marginTop: '20px', 
                margin: '0 auto', 
                padding: '16px', 
                backgroundColor: '#d51317' 
              }}>
              <CardContent>
                <Grid 
                  container 
                  spacing={2} 
                  sx={{ marginTop: '16px' }}>
                  
                  <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center' 
                    }}>
                    <Typography variant="h4" color="#FFFFFF" align="center">
                      <span style={{ fontWeight: 'Regular' }}>
                        ¡Entra a la familia Aina Car y recibe un 5% de descuento en tu primer alquiler reservando por la web!
                      </span>
                    </Typography>
                  </Grid>
                  
                  <Grid 
                    item 
                    xs={12} 
                    md={6}
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'center' 
                    }}>
                    <form noValidate autoComplete="off">
                      <CustomTextField
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                      />
                      <CustomTextField
                        label="Correo Electrónico"
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                      />
                      <Button 
                        disabled 
                        sx={{ width: '100%', boxShadow:'none', color:'#031b27' }} 
                        variant="contained" 
                        color="white" 
                        type="submit">
                        Solicitar
                      </Button>
                    </form>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          </Grid>
        </MKBox>
      </div>
    </>
  );
};

export default Karve02;*/


/*
import React, { useEffect, useState , useRef} from 'react';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import KarveIframe from './ReservationWidget';
import { Card, CardContent, Typography, Grid, TextField, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
const Karve02 = ({ isOpenReservation, reservationDrawer, estadoPrincipal }) => {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight * 0.8); 
  const buttonRef = useRef(null);
  const [buttonHeight, setButtonHeight] = useState(0);
  const [formData, setFormData] = useState({ nombre: '', email: '', tel: '', message: '' });
  const [responseMessage, setResponseMessage] = useState('');

  const token = 'mi-token-estatico';

  const CustomTextField = styled(TextField)({
    '& label': {
      color: '#FFFFFF',
    },
    '& label.Mui-focused': {
      color: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFFFFF',
        borderWidth: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFFFFF',
      },
      color: '#FFFFFF',
    },
  });

  useEffect(() => {
    if (buttonRef.current) {
      const height = buttonRef.current.clientHeight;
      setButtonHeight(height);
    }
  }, [isOpenReservation]);

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(window.innerHeight * 0.8); 
    };

    const handleIframeMessage = (event) => {
      if (event.origin.includes('karveinformatica.com')) {
        if (event.data.height) {
          setIframeHeight(event.data.height);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };
  const handleMailSubmit = async (event) => {
      event.preventDefault();
      try {
          console.log('Enviando datos:', formData);

          const response = await fetch(`/api/public/dto?token=${token}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
          });

          const textResponse = await response.text(); // Obtén la respuesta como texto
          console.log('Respuesta en texto:', textResponse);

          const data = JSON.parse(textResponse); // Luego intenta parsear el JSON

          if (response.ok) {
              if (data.success) {
                  setResponseMessage('Cliente registrado y correo enviado exitosamente.');
              } else if (data.message) { 
                  // Manejo del caso en que el cliente ya está registrado
                  setResponseMessage(data.message);
              } else {
                  setResponseMessage('Ocurrió un problema con la solicitud.');
              }
          } else {
              setResponseMessage('Error en la solicitud. Por favor, inténtalo de nuevo.');
          }
      } catch (error) {
          console.error('Error al enviar los datos:', error);
          setResponseMessage('Error al enviar los datos');
      }
  };

  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {isMobileDevice() ? (
        <Box 
          sx={{ 
            position: 'fixed', 
            top: '90%', 
            left: '0', 
            transform: `translate(-50%, -50%) rotate(90deg)`, 
            zIndex: '1001', 
            opacity: isOpenReservation ? "0%" : "100%" 
          }}>
          <MKButton 
            ref={buttonRef} 
            onClick={() => {reservationDrawer()}} 
            variant="contained" 
            color="primary" 
            style={{ 
              borderBottomLeftRadius: '0', 
              borderBottomRightRadius: '0', 
              boxShadow: 'none', 
              padding: '8px 16px', 
              width: 'max-content', 
              marginBottom: buttonHeight 
            }}>
            {isOpenReservation ? '' : 'Reservar'}
          </MKButton>
        </Box>
      ) : (null)}

      {isOpenReservation && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(15px)',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            position: 'fixed',
            left: '0',
            zIndex: '1000'
          }}
        />
      )}

      <div
        style={{
          width: isMobileDevice() ? '100%' : '70%',
          maxHeight: '80%', 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '1000',
          display: isOpenReservation ? 'block' : 'none',
          borderRadius: '8px', 
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)' 
        }}>
        <MKBox sx={{ position: 'absolute', top: '-6%', right: '0%', zIndex: 1 }}>
          <MKButton
            variant="text"
            onClick={reservationDrawer}
            sx={{ color: 'rgba(255, 255, 255, 1)', borderRadius: '5px', padding: '16px' }}>
            Cerrar
          </MKButton>
        </MKBox>

        <MKBox width="100%" sx={{}}>
          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              height: '100%',
            }}>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' },
                height: '100%',
              }}>
              <div style={{ width: '100%' }}>
                <KarveIframe estadoPrincipal={estadoPrincipal} />
              </div>
            </Grid>
            <Card 
              sx={{ 
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' }, 
                marginTop: '20px',
                marginBottom: '20px',  
                padding: { xs: '8px', sm: '12px', md: '16px' },
                backdropFilter: 'blur(15px)',
                backgroundColor: 'rgba(255, 255, 255, 0.20)', 
                boxShadow: 'none',
                alignSelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}>
              <CardContent>
                <Typography 
                  variant="h5" 
                  color="#FFFFFF" 
                  align="center" 
                  sx={{ 
                    fontSize: { xs: '16px', sm: '18px', md: '20px' },
                    wordWrap: 'break-word', 
                    overflowWrap: 'break-word'
                  }}>
                  <span style={{ fontWeight: 'Regular' }}>
                    ¡Atención! Puedes encontrar vehículos con bola de remolque. Busca los vehículos que tengan '(+ opción de BOLA REMOLQUE)' junto a su nombre en nuestro motor de búsqueda.
                  </span>
                </Typography>
              </CardContent>
            </Card>
            <Grid 
              item 
              sx={{ 
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' },
                height: '100%', 
                marginTop: '0px', 
              }}>
              <Card 
                sx={{ 
                  minHeight: '200px', 
                  width: '100%', 
                  marginTop: '20px', 
                  margin: '0 auto', 
                  padding: '16px', 
                  backgroundColor: '#d51317' 
                }}>
                <CardContent>
                  <Grid 
                    container 
                    spacing={2} 
                    sx={{ marginTop: '16px' }}>
                    <Grid 
                      item 
                      xs={12} 
                      md={6} 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center' 
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h4" color="#FFFFFF" align="center">
                          <span style={{ fontWeight: 'Regular' }}>
                            ¡Entra a la familia Aina Car y recibe un 5% de descuento en tu primer alquiler reservando por la web!
                          </span>
                        </Typography>
                        <Typography variant="h6" color="#FFFFFF" align="center" sx={{ marginTop: 2, width:'80%' }}>
                          <span style={{ fontWeight: 'light' }}>
                            Si es aprobado, se tramitará posteriormente por el equipo de atención al cliente que se pondrá en contacto con usted.
                          </span>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid 
                      item 
                      xs={12} 
                      md={6}
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center' 
                      }}>
                      <form onSubmit={handleMailSubmit} style={{ width: '100%' }}>
                        <CustomTextField
                          name="nombre"
                          label="Nombre"
                          type="text"
                          fullWidth
                          margin="normal"
                          required
                          value={formData.nombre}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          name="email"
                          label="Email"
                          type="email"
                          fullWidth
                          margin="normal"
                          required
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          name="tel"
                          label="Teléfono"
                          type="tel"
                          fullWidth
                          margin="normal"
                          required
                          value={formData.tel}
                          onChange={handleChange}
                        />
                        <CustomTextField
                          name="message"
                          label="Mensaje"
                          multiline
                          rows={4}
                          fullWidth
                          margin="normal"
                          required
                          value={formData.message}
                          onChange={handleChange}
                        />
                        <Button type="submit" sx={{marginTop:2, width: '100%', boxShadow:'none', color:'#031b27' }} 
                        variant="contained" 
                        color="white"  startIcon={<MailIcon />}>
                          Solicitar
                        </Button>
                      </form>
                      {responseMessage && <Typography variant="body2" color="error">{responseMessage}</Typography>}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
      </div>
    </>
  );
};

export default Karve02;*/

import React, { useEffect, useState, useRef } from 'react';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import KarveIframe from './ReservationWidget';
import ReservationForm from './testform'; // Importa el formulario separado
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const Karve02 = ({ isOpenReservation, reservationDrawer, estadoPrincipal }) => {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight * 0.8);
  const [responseMessage, setResponseMessage] = useState('');
  const token = 'mi-token-estatico';
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const [buttonHeight, setButtonHeight] = useState(0);

  useEffect(() => {
    if (buttonRef.current) {
      const height = buttonRef.current.clientHeight;
      setButtonHeight(height);
    }
  }, [isOpenReservation]);

  useEffect(() => {
    const handleResize = () => {
      setIframeHeight(window.innerHeight * 0.8);
    };

    const handleIframeMessage = (event) => {
      if (event.origin.includes('karveinformatica.com')) {
        if (event.data.height) {
          setIframeHeight(event.data.height);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };

  const handleMailSubmit = async (event, formData) => {
    event.preventDefault();
    try {
      const response = await fetch(`/api/public/dto?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const textResponse = await response.text();
      const data = JSON.parse(textResponse);

      if (response.ok) {
        if (data.success) {
          setResponseMessage('Cliente registrado y correo enviado exitosamente.');
        } else if (data.message) {
          setResponseMessage(data.message);
        } else {
          setResponseMessage('Ocurrió un problema con la solicitud.');
        }
      } else {
        setResponseMessage('Error en la solicitud. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      setResponseMessage('Error al enviar los datos');
    }
  };

  return (
    <>
      {isMobileDevice() ? (
        <Box 
          sx={{ 
            position: 'fixed', 
            top: '90%', 
            left: '0', 
            transform: 'translate(-50%, -50%) rotate(90deg)', 
            zIndex: '1001', 
            opacity: isOpenReservation ? "0%" : "100%" 
          }}>
          <MKButton 
            ref={buttonRef} 
            onClick={() => {window.location.href = "/reservar"; reservationDrawer()}}
            variant="contained" 
            color="primary" 
            style={{ 
              borderBottomLeftRadius: '0', 
              borderBottomRightRadius: '0', 
              boxShadow: 'none', 
              padding: '8px 16px', 
              width: 'max-content', 
              marginBottom: buttonHeight 
            }}>
            {isOpenReservation ? '' : 'Reservar'}
          </MKButton>
        </Box>
      ) : null}

      {isOpenReservation && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(15px)',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            position: 'fixed',
            left: '0',
            zIndex: '1000'
          }}
        />
      )}

      <div
        style={{
          width: isMobileDevice() ? '100%' : '70%',
          maxHeight: '80%', 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '1000',
          display: isOpenReservation ? 'block' : 'none',
          borderRadius: '8px', 
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)' 
        }}>
        <MKBox sx={{ position: 'absolute', top: '-6%', right: '0%', zIndex: 1 }}>
          <MKButton
            variant="text"
            onClick={() => {navigate("/"); reservationDrawer()}}
            //onClick={reservationDrawer}
            sx={{ color: 'rgba(255, 255, 255, 1)', borderRadius: '5px', padding: '16px' }}>
            {t("cerrar")}
          </MKButton>
        </MKBox>

        <MKBox width="100%">
          <Grid
            container
            spacing={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
              height: '100%',
            }}>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' },
                height: '100%',
              }}>
              <div style={{ width: '100%' }}>
                <KarveIframe estadoPrincipal={estadoPrincipal} />
              </div>
            </Grid>
            <Card 
              sx={{ 
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' }, 
                marginTop: '20px',
                marginBottom: '20px',  
                padding: { xs: '8px', sm: '12px', md: '12px' },
                backdropFilter: 'blur(15px)',
                backgroundColor: 'rgba(255, 255, 255, 0.20)', 
                boxShadow: 'none',
                alignSelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}>
              <CardContent
               
              >
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: '#FFFFFF',
                    fontWeight: 400, 
                    fontSize: { xs: '14px', sm: '16px', md: '20px' }, 
                    lineHeight: 1.5,
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'normal', 
                  }}
                >
                  {t('bola_remolque')}
                </Typography>
              </CardContent>

            </Card>
            <Grid 
              item 
              sx={{ 
                width: { xs: '95%', sm: '100%', md: '100%', lg: '100%' },
                height: '100%', 
                marginTop: '0px', 
              }}>
              <Card
  sx={{
    minHeight: '200px',
    width: '100%',
    marginTop: '0px',
    padding: '16px',
    backgroundColor: '#d51317',
    borderRadius: '16px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  }}
>
  <CardContent>
    <Grid
      container
      spacing={4}
      sx={{ marginTop: '16px', alignItems: 'center' }}
    >
      {/* Lado izquierdo con texto */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h4" color="#FFFFFF" sx={{ fontWeight: 500 }}>
            {t('dto_5_1')}
          </Typography>
          <Typography
            variant="h6"
            color="#FFFFFF"
            sx={{ marginTop: 2, fontWeight: 300, width: '80%' }}
          >
            {t('dto_5_2')}
          </Typography>
          <Typography
  variant="caption"
  color="#FFFFFF"
  sx={{
    textAlign: "justify",
    marginTop: 6,
    fontWeight: 200,
    width: "90%",
    lineHeight: 1.6,
    "& a": {
      textDecoration: "underline !important", // Fuerza el subrayado
      color: "blue !important", // Fuerza el color azul
      fontWeight: "regular", // Opcional para mayor visibilidad
    },
  }}
>
  <strong>{t("dto_5_responsabilidad_title")}</strong> ALQUILER AINA CAR, S.L.
  <br />
  <strong>{t("dto_5_finalidad_title")}</strong> {t("dto_5_finalidad_content")}
  <br />
  <strong>{t("dto_5_legitimacion_title")}</strong> {t("dto_5_legitimacion_content")}
  <br />
  <strong>{t("dto_5_cesiones_title")}</strong> {t("dto_5_cesiones_content")}
  <br />
  <strong>{t("dto_5_derechos_title")}</strong> {t("dto_5_derechos_content")}
  <a
    
    href="https://www.aepd.es"
    target="_blank"
    rel="noopener noreferrer"
  >
    (www.aepd.es)
  </a>
  <br />
  <strong>{t("dto_5_info_adicional_title")}</strong>{" "}
  <a
    
    href="/politica-privacidad"
    target="_blank"
    rel="noopener noreferrer"
  >
    {t("dto_5_info_adicional_content")}
  </a>
</Typography>

        </Box>
      </Grid>

      {/* Lado derecho con formulario */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ReservationForm
          onSubmit={handleMailSubmit}
          responseMessage={responseMessage}
        />
      </Grid>
    </Grid>
  </CardContent>
</Card>

            </Grid>
          </Grid>
        </MKBox>
      </div>
    </>
  );
};

export default Karve02;


