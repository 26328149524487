import * as React from "react";
import { lazy, Suspense, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ResponsiveAppBar from "../AinaHome/NavbarTest";
import { useTranslation, Trans } from "react-i18next";
import AinaCarRentACAR from "assets/images/atencion.webp";
import footerRoutes from "footer.routes";
import Container from "@mui/material/Container";
import Whatsapp from "../AinaHome/whatsapp";
import Karve02 from "../AinaHome/karve02";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Footer from "../AinaHome/footer";

function CustomCard({ title, description, sections = [] }) {
  return (
    <Card
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "none",
        borderRadius: "0px",
        background: "none",
        mb: 4,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          textAlign: "justify",
        }}
      >
        <MKTypography mt={3} mb={3} gutterBottom variant="h5" component="div">
          {title}
        </MKTypography>

        <MKTypography variant="body2" color="text">
          <Box
            sx={{
              "& a": {
                textDecoration: "underline !important",
                color: "#d6061e !important",
                fontWeight: "regular",
                display: "inline",
                cursor: "pointer",
              },
            }}
          >
            {sections.length > 0 ? (
              <Box style={{ paddingLeft: "0px" }}>
                {sections.map((section) => (
                  <Box key={section.id}>
                    <br />
                    <MKTypography variant="body" color="text">
                      <strong>{section.title}</strong>
                    </MKTypography>
                    <br />
                    <Trans
                      i18nKey={section.content}
                      components={{
                        ul: <ul style={{ paddingLeft: "20px" }} />,
                        li: <li />,
                        bold: <strong />,
                        link1: (
                          <a
                            href="https://www.ainacar.cat"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        link2: (
                          <a
                            href="https://www.ainacar.es"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        link3: (
                          <a
                            href="https://www.ainacar.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Trans
                i18nKey={description}
                components={{
                  ul: <ul style={{ paddingLeft: "20px" }} />,
                  li: <li />,
                  bold: <strong />,
                  link1: (
                    <a
                      href="https://www.ainacar.cat"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                  link2: (
                    <a
                      href="https://www.ainacar.es"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                  link3: (
                    <a
                      href="https://www.ainacar.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                  
                  link4: (
                    <a
                      href="/politica-cookies"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ),
                  link5: (
                    <a
                      href="/politica-privacidad"
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  )
                }}
              />
            )}
          </Box>
        </MKTypography>
      </CardContent>
    </Card>
  );
}

function LegalNotice({ isReservationOpen = false }) {
  const [valorHijo, setValorHijo] = useState("");
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef(null);
  const { t } = useTranslation();

  const [isOpenReservation, setIsOpenReservation] = useState(isReservationOpen);
  const reservationDrawer = () => setIsOpenReservation(!isOpenReservation);

  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("reserve") === "true") setIsOpenReservation(true);
  }, [location]);

  useEffect(() => {
    if (appBarRef.current) setAppBarHeight(appBarRef.current.clientHeight);
  }, []);

  const manejarCambio = (nuevoValor) => setValorHijo(nuevoValor);

  const back = AinaCarRentACAR;

  const legalNoticeSections = [
    {
      id: "introduction",
      title: t("legal_notice.introduction.title"),
      content: "legal_notice.introduction.content"
    },
    {
      id: "object",
      title: t("legal_notice.object.title"),
      content: "legal_notice.object.content"
    },
    {
      id: "conditions",
      title: t("legal_notice.conditions.title"),
      sections: [
        {
          id: "acceso_gratuito",
          title: t("legal_notice.conditions.sections.acceso_gratuito.title"),
          content: "legal_notice.conditions.sections.acceso_gratuito.content"
        },
        {
          id: "registro_usuario",
          title: t("legal_notice.conditions.sections.registro_usuario.title"),
          content: "legal_notice.conditions.sections.registro_usuario.content"
        },
        {
          id: "veracidad_informacion",
          title: t("legal_notice.conditions.sections.veracidad_informacion.title"),
          content: "legal_notice.conditions.sections.veracidad_informacion.content"
        },
        {
          id: "usuarios_autorizados",
          title: t("legal_notice.conditions.sections.usuarios_autorizados.title"),
          content: "legal_notice.conditions.sections.usuarios_autorizados.content"
        },
        {
          id: "uso_correcto",
          title: t("legal_notice.conditions.sections.uso_correcto.title"),
          content: "legal_notice.conditions.sections.uso_correcto.content"
        }
      ]
    },
    {
      id: "responsabilidad",
      title: t("legal_notice.responsabilidad.title"),
      content: "legal_notice.responsabilidad.content"
    },
    {
      id: "cookies",
      title: t("legal_notice.cookies.title"),
      content: "legal_notice.cookies.content"
    },
    {
      id: "links",
      title: t("legal_notice.links.title"),
      content: "legal_notice.links.content"
    },
    {
      id: "data_protection",
      title: t("legal_notice.data_protection.title"),
      content: "legal_notice.data_protection.content"
    },
    {
      id: "intellectual_property",
      title: t("legal_notice.intellectual_property.title"),
      content: "legal_notice.intellectual_property.content"
    },
    {
      id: "jurisdiction",
      title: t("legal_notice.jurisdiction.title"),
      content: "legal_notice.jurisdiction.content"
    },
  ];

  return (
    <>
      <Karve02
        isOpenReservation={isOpenReservation}
        reservationDrawer={reservationDrawer}
      />

      <Box sx={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
        <Box
          ref={appBarRef}
          style={{
            position: "sticky",
            top: valorHijo ? "15px" : "20px",
            transition: valorHijo ? "top 1s ease-in" : "top 0.0s ease-out",
            zIndex: 3,
          }}
        >
          <ResponsiveAppBar
            reservationDrawer={reservationDrawer}
            onCambio={manejarCambio}
          />
        </Box>

        <Box
          sx={{
            minHeight: "69vh",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), white), url(${back})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: `-${appBarHeight}px`,
          }}
        >
          <MKBox
            sx={{
              justifyContent: "bottom",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Container>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <MKTypography
                    color="white"
                    variant="h1"
                    sx={{
                      textAlign: "center",
                      textShadow: "0px 2px 60px rgba(0, 0, 0, 0.999)",
                    }}
                  >
                    {t("legal_notice.title")}
                  </MKTypography>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
        </Box>

        <Container>
          <Box mt={0} mb={4}>
            {legalNoticeSections.map((section) => (
              <CustomCard
                key={section.id}
                title={section.title}
                description={section.content}
                sections={section.sections || []}
              />
            ))}
          </Box>
        </Container>

        <MKBox bgColor="info" mt={10}>
          <MKBox>
            <Suspense fallback={<div>Loading...</div>}>
              <Footer pt={6} px={1} content={footerRoutes} />
            </Suspense>
          </MKBox>
        </MKBox>
      </Box>
    </>
  );
}

export default LegalNotice;
