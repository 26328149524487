/* global fbq */

import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import zIndex from "@mui/material/styles/zIndex";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import LogoAinaBlack from "assets/images/LogoAinaBlack.svg";
import CookieImg from "assets/images/galleta.png";
import { Typography } from "@mui/material";
import { Switch, FormControlLabel } from "@mui/material";
import { useTranslation } from 'react-i18next';


const loadFacebookPixel = (pixelId) => {
  if (!pixelId) {
    //console.error("Facebook Pixel ID no está definido.");
    return;
  }

  // Evitar inicializar el Pixel más de una vez
  if (window.fbq && window.fbq.initialized) {
    //console.warn("Facebook Pixel ya está inicializado.");
    return;
  }

  (function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

  //window.fbq.l = new Date();
  fbq("init", pixelId);
  //window.fbq("set", "autoConfig", true, pixelId);
  fbq.initialized = true;
  fbq("track", "PageView");

  //console.log("Facebook Pixel cargado correctamente.");

  // Agregar el noscript para fallback si JavaScript está deshabilitado
  if (!document.getElementById("facebook-noscript")) {
    const noscript = document.createElement("noscript");
    noscript.id = "facebook-noscript";
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
    `;
    document.body.appendChild(noscript);
  }
};


const CookieBanner = () => {
  const analyticsToken = process.env.REACT_APP_GOOGLE_ANALYTICS_TOCKEN;
  const tidioToken = process.env.REACT_APP_TIDIO_TOKEN;
  const facebookPixelId = process.env.REACT_APP_META_TOKEN;
  const { t } = useTranslation();


  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [preferences, setPreferences] = useState({
    analytics: false,
    marketing: false,
    functional: false,
  });
  const [cookiesTypes, setCookiesTypes] = useState([]);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const isMobileDevice = () => {
    return window.innerWidth <= 1000;
  };


  // Cargar preferencias desde las cookies
  useEffect(() => {
    const storedPrefs = Cookies.get("cookiePreferences");
    if (!storedPrefs) {
      setIsVisible(true);
    } else {
      const parsedPrefs = JSON.parse(storedPrefs);
      setPreferences(parsedPrefs);
      applyCookiePreferences(parsedPrefs);
    }
  }, []);

  // Guardar preferencias y aplicar cambios sin recargar
  const savePreferences = () => {
    Cookies.set("cookiePreferences", JSON.stringify(preferences), {
      expires: 365,
    });
    setIsVisible(false);
    setIsSettingsOpen(false);
    applyCookiePreferences(preferences);
  };

  // Aceptar todas las cookies
  const acceptAll = () => {
    const allAccepted = { analytics: true, marketing: true, functional: true };
    Cookies.set("cookiePreferences", JSON.stringify(allAccepted), {
      expires: 365,
    });
    setPreferences(allAccepted);
    setIsVisible(false);
    setIsSettingsOpen(false);
    applyCookiePreferences(allAccepted);
  };

  // Rechazar todas las cookies
  const rejectAll = () => {
    const allRejected = {
      analytics: false,
      marketing: false,
      functional: false,
    };
    Cookies.set("cookiePreferences", JSON.stringify(allRejected), {
      expires: 365,
    });
    setPreferences(allRejected);
    setIsVisible(false);
    setIsSettingsOpen(false);
    applyCookiePreferences(allRejected);
  };

  // Manejar cambios en los switches
  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;

    setPreferences((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Activar/desactivar scripts según las preferencias
  const applyCookiePreferences = (prefs) => {
    // Google Analytics
    if (prefs.analytics) {
      loadScript(
        `https://www.googletagmanager.com/gtag/js?id=${analyticsToken}`,
        "google-analytics"
      );

      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", analyticsToken);
    } else {
      removeScript("google-analytics");
    }

    // Tidio Chat
    if (prefs.functional) {
      loadScript(`//code.tidio.co/${tidioToken}.js`, "Tidio Chat code");
    } else {
      removeScript("Tidio Chat code");
    }

    // Facebook Pixel
    if (prefs.marketing && facebookPixelId) {
      loadFacebookPixel(facebookPixelId);
    } else {
      removeScript("facebook-pixel");
  
      const existingNoscript = document.getElementById("facebook-noscript");
      if (existingNoscript) {
        existingNoscript.remove();
        console.log("Facebook noscript eliminado.");
      }
    }
    
  
  };

  // Cargar scripts dinámicamente
  const loadScript = (src, id, callback) => {
    if (document.getElementById(id)) return; // Evita duplicados
  
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => {
      console.log(`Script ${id} cargado correctamente`);
      if (callback) callback(); // Ejecutar el callback después de cargar el script
    };
    document.body.appendChild(script);
  };

  // Eliminar scripts cuando el usuario rechaza cookies
  const removeScript = (id) => {
    const script = document.getElementById(id);
    if (script) {
      script.remove();
      console.log(`Script ${id} eliminado.`);
    }
  };

  const cookiesOptions = [
    { item:t('cookies_boton_aceptar'), action: acceptAll },
    { item: t('cookies_boton_seleccion'), action: savePreferences },
    { item: t('cookies_boton_rechazar'), action: rejectAll },
  ];

  useEffect(() => {
    setCookiesTypes([
      { name: "necessary", item: t('cookies_necesarias'), disabled: true, checked: true },
      {
        name: "functional",
        item: t('cookies_preferencias'),
        disabled: false,
        checked: preferences.functional,
      },
      {
        name: "analytics",
        item: t('cookies_Estadisticas'),
        disabled: false,
        checked: preferences.analytics,
      },
      {
        name: "marketing",
        item: t('cookies_marketing'),
        disabled: false,
        checked: preferences.marketing,
      },
    ]);
  }, [preferences, t]);

  const cookiesDetails = [
    {
      type: t('cookies_Estadisticas'),
      category: "Google Analytics",
      cookies: [
        {
          name: "_ga",
          description: t('cookies_Estadisticas_1'),
          duration: t('cookies_Estadisticas_1_duration'),
        },
        {
          name: "_gid",
          description: t('cookies_Estadisticas_2'),
          duration: t('cookies_Estadisticas_2_duration'),
        },
        {
          name: "_gat",
          description: t('cookies_Estadisticas_3'),
          duration: t('cookies_Estadisticas_3_duration'),
        },
      ],
    },
    {
      type: t('cookies_marketing'),
      category: "Facebook Pixel",
      cookies: [
        {
          name: "_fbp",
          description: t('cookies_marketing_1'),
          duration: t('cookies_marketing_1_duration'),
        },
        {
          name: "fr",
          description: t('cookies_marketing_2'),
          duration: t('cookies_marketing_2_duration'),
        },
      ],
    },
    {
      type: t('cookies_preferencias'),
      category: "Tidio Chat",
      cookies: [
        {
          name: "tidio_state",
          description: t('cookies_preferencias_1'),
          duration: t('cookies_preferencias_2'),
        },
        {
          name: "tidio_id",
          description: t('cookies_preferencias_1_duration'),
          duration: t('cookies_preferencias_2_duration'),
        },
      ],
    },
  ];

  //if (!isVisible) return null;

  return (
    <Box>
      {!isSettingsOpen && !isVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            left: isMobileDevice() ? '50px' : '20px',
            zIndex: 1000,
          }}
        >
          <Card
            sx={{
              backgroundColor: "#ffffff",
              color: "#000000",
              width: "55px",
              height: "55px",
              minWidth: "50px",
              borderRadius: "50%",
              padding: "10px",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              marginBottom: "8px",
              
            }}
            onClick={() => {
              setIsSettingsOpen(true);
            }}
          >
            <Box
              component="img"
              src={CookieImg}
              alt={"CookieImg"}
              sx={{
                zIndex: 1,
                width: "100%",
              }}
            />
          </Card>
        </Box>
      )}

      {(isVisible || isSettingsOpen) && (
        <Box
          sx={{
            zIndex: 999,
            position: "fixed",
            bottom: "5%",
            left: "10%",
            right: "10%",
            maxHeight: "80vh",
            overflowY: "auto",
            //height: isOpen ? '400px' : 'auto' ,
            transition: "height 0.3s ease-in-out",
            background: "#333",
            color: "white",
            padding: "10px",
            textAlign: "center",
            backgroundColor: "rgba(255, 255, 255, 1)",
            color: "white",
            padding: "2rem",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          }}
        >
          <Grid container spacing={6} justifyContent="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={1.5}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  textAlign: "center",

                  position: "relative",
                  zIndex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                {/* IMAGEN */}
                <Box
                  component="img"
                  src={LogoAinaBlack}
                  alt={"Logotipo Ainacar Black"}
                  sx={{
                    zIndex: 1,
                    width: "100%",
                    maxWidth: "120px",
                    borderRadius: "12px",
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8.5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  variant="body2"
                  color="white"
                  sx={{
                    zIndex:2,
                    fontSize: "75%",
                    lineHeight: "1.4",
                    minHeight: "110px",
                    textAlign: "left",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "opacity 0.3s ease-in-out",
                    opacity: 1,
                    "& a": {
                      textDecoration: "underline !important", // Fuerza el subrayado
                      color: "#d6061e !important", // Fuerza el color azul
                      fontWeight: "regular", // Opcional para mayor visibilidad
                      display: "inline",
                      cursor: "pointer",

                    },
                  }}
                >
                  <strong>{t('cookies_text_title')}</strong> <br /> <br />{" "}
                  {t('cookies_text_content')}
                  <a
                    href="/politica-cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('cookies_more_info')}
                  </a>
                  . {t('cookies_text_content_2')}
                </Typography>

                <Grid sx={{zIndex:1,}} container spacing={0} justifyContent="center">
                  {cookiesTypes.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      xs={6}
                      sm={6}
                      md={2.4}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            name={item.name}
                            disabled={item.disabled}
                            checked={item.checked}
                            onChange={handleSwitchChange}
                          />
                        }
                        label={item.item}
                      />
                    </Grid>
                  ))}

                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={2.4}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card
                      sx={{
                        boxShadow: "none",
                        width: "180px",
                        backgroundColor: "transparent",
                        //border: "1px solid #d6061e",
                        padding: "10px",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginBottom: "8px",
                      }}
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: "#d6061e", fontSize: "70%" }}
                      >
                        {t('cookies_mostrar_detalles')}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  position: "relative",
                  zIndex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                {cookiesOptions.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      boxShadow: "none",
                      width: "180px",
                      backgroundColor: "transparent",
                      border: "1px solid #d6061e",
                      padding: "10px",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginBottom: "8px",
                    }}
                    onClick={() => {
                      return item.action && item.action();
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "#d6061e", fontSize: "70%" }}
                    >
                      {item.item}
                    </Typography>
                  </Card>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Collapse in={isOpen}>
            <Box
              sx={{
                backgroundColor: "#fafbfd",
                borderRadius: "8px",
                padding: "20px",
                marginTop: "30px",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  marginBottom: "20px",
                  fontWeight: "bold",
                }}
              >
                {t('cookies_mostrar_detalles_categoria')}
              </Typography>

              <Grid container spacing={4} justifyContent="center">
                {cookiesDetails.map((group, index) => (
                  <Grid item key={index} xs={12} sm={6} md={6} xl={4}>
                    <Card
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        padding: "20px",
                        boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                        textAlign: "center",
                        height: "100%",
                      }}
                    >
                      {/* Categoría de la cookie */}
                      <Typography
                        variant="body2"
                        sx={{
                          backgroundColor: "#fafbfd",
                          color: "rgba(0,0,0,0.5)",
                          padding: "4px 8px",
                          borderRadius: "5px",
                          display: "inline-block",
                          fontSize: "80%",
                          marginBottom: "10px",
                        }}
                      >
                        {group.type}
                      </Typography>

                      {/* Nombre del grupo (Ej: Google Analytics, Facebook Pixel) */}
                      <Typography
                        variant="h6"
                        sx={{
                          marginBottom: "10px",
                          fontWeight: "bold",
                          color: "#d6061e",
                        }}
                      >
                        {group.category}
                      </Typography>

                      {/* Lista de cookies */}
                      {group.cookies.map((cookie, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            textAlign: "left",
                            fontSize: "70%",
                            paddingBottom: "6px",
                            marginBottom: "10px",
                            borderBottom: "1px solid #f5f5f5",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "100%",
                              flex: "1 1 30%",
                            }}
                          >
                            {cookie.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ fontSize: "100%", flex: "1 1 70%" }}
                          >
                            {cookie.description} ({cookie.duration})
                          </Typography>
                        </Box>
                      ))}
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

export default CookieBanner;
