/* global fbq */
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useFacebookPixel = () => {
  const location = useLocation();
  const hasFired = useRef(false);

  useEffect(() => {
    if (window.fbq && window.fbq.initialized) {
      if (!hasFired.current) {
        fbq("track", "PageView");
        //console.log(`✅ Evento PageView enviado para: ${location.pathname}`);
        hasFired.current = true;
      }
    }

    return () => {
      hasFired.current = false;
    };
  }, [location.pathname]);
};

export default useFacebookPixel;
