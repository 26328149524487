/*import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const KarveIframe = ({ margin }) => {
  const { i18n } = useTranslation();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const loadScript = (src, id, dataset) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        if (id) script.id = id;
        if (dataset) {
          for (const key in dataset) {
            script.dataset[key] = dataset[key];
          }
        }
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.body.appendChild(script);
      });
    };

    const removeScript = (src) => {
      const existingScript = document.querySelector(`script[data-src="${src}"]`);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };

    const communicationScriptSrc = 'https://iframes.karveinformatica.com/AinacarIframe/js/iframe-comunication-parent.js';

    const init = async () => {
      try {
        removeScript(communicationScriptSrc);
        await loadScript(communicationScriptSrc, 'karve-comunication-script', { minheight: '500' });
      } catch (error) {
        console.error(error);
      }
    };

    init();

    return () => {
      removeScript(communicationScriptSrc);
    };
  }, [i18n.language]);

  return (
    <div style={{ 
      position: 'relative', 
      top: `${margin}px`,
      left: '0px', 
      width: '100%', 
      height: '20%', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
    }}>
      <iframe
        style={{
          width: "100%",
          borderRadius: '12px',
          border: 'none',
        }}
        id="karve-iframe"
        width="100%"
        frameBorder="0"
        scrolling="no"
        data-src={`https://iframes.karveinformatica.com/AinacarIframe/views/home.php?lang=${i18n.language}&initial-category-filter-ids=I`}
        data-src-loading="https://iframes.karveinformatica.com/AinacarIframe/views/loading.php"
        onLoad={() => setIframeLoaded(true)}
       
      ></iframe>
      {!iframeLoaded && <div>Loading...</div>}
    </div>
  );
};

export default KarveIframe;*/






import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const KarveIframe = ({ margin, estadoPrincipal }) => {
  const { i18n } = useTranslation();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const location = useLocation();
  const [iframeKey, setIframeKey] = useState(0);
  
//  // console.log('eee', estadoPrincipal)
//   useEffect(() => {

//     const scriptExists = (src) => {
//       return document.querySelector(`script[src="${src}"]`) !== null;
//     };

//     const loadScript = async (src, id, dataset) => {
//       if (scriptExists(src)) {
//         console.log(`El script ${src} ya está cargado. Evitando recarga.`);
//         return;
//       }
    
//       return new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = src;
//         if (id) script.id = id;
//         if (dataset) {
//           for (const key in dataset) {
//             script.dataset[key] = dataset[key];
//           }
//         }
//         script.onload = () => resolve(script);
//         script.onerror = () => reject(new Error(`Error al cargar el script ${src}`));
//         document.body.appendChild(script);
//         console.log(`Script agregado: ${src}`);
//       });
//     };

//     const removeScript = (src) => {
//       const existingScript = document.querySelector(`script[src="${src}"]`);
//       if (existingScript) {
//         existingScript.parentNode.removeChild(existingScript);
//         console.log(`Script eliminado: ${src}`);
//       }
//     };

//     const communicationScriptSrc = 'https://iframes.karveinformatica.com/AinacarIframe/js/iframe-comunication-parent.js';

//     const init = async () => {
//       try {
//         removeScript(communicationScriptSrc);
//         await loadScript(communicationScriptSrc, 'karve-comunication-script', { minheight: '500' });
//       } catch (error) {
//         console.error(error);
//       }
//     };

//     init();

//     // const customScriptSrc = 'https://iframes.karveinformatica.com/AinacarIframe/custom/custom-events.js';

//     // const CustomInit = async () => {
//     //   try {
//     //     console.log("🔄 Intentando recargar CustomEvents...");
    
//     //     // ❌ Si CustomEvents ya existe, no recargamos el script
//     //     if (window.CustomEvents) {
//     //       console.log("❌ CustomEvents ya existe, no se recarga.");
//     //       return;
//     //     }
    
//     //     // 🔥 Si el script ya existe en el DOM, tampoco lo recargamos
//     //     if (!scriptExists(customScriptSrc)) {
//     //       await loadScript(customScriptSrc, 'karve-custom-script', { minheight: '500' });
//     //       console.log("✅ CustomEvents ha sido recargado correctamente.");
//     //     }
//     //   } catch (error) {
//     //     console.error("❌ Error recargando CustomEvents:", error);
//     //   }
//     // };

//     // CustomInit();

//     return () => {
//       removeScript(communicationScriptSrc);
//       //removeScript(customScriptSrc);
//     };
//   }, [i18n.language, estadoPrincipal]); // Lista de dependencias: i18n.language y configuration

  return (
    <div style={{
      position: 'relative',
      top: `${margin}px`,
      left: '0px',
      width: '100%',
      height: '100%', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <iframe
        key={`${location.pathname}-${i18n.language}-${estadoPrincipal}`}
        style={{
          width: "100%",
          borderRadius: '12px',
          border: 'none',
          height: '100%'
        }}
        id="karve-iframe"
        width="100%"
        frameBorder="0"
        scrolling="no"
        data-src={`https://iframes.karveinformatica.com/AinacarIframe/views/home.php?lang=${i18n.language}${estadoPrincipal || ''}`}
        data-src-loading="https://iframes.karveinformatica.com/AinacarIframe/views/loading.php"
        onLoad={() => setIframeLoaded(true)}
      ></iframe>
      {!iframeLoaded && <div>Loading...</div>}
    </div>
  );
};

export default KarveIframe;
